.dicesvg {
  width: 60vw;
  margin: 40px auto;
}

#dice1,
#dice2,
#dice3 {
  opacity: 0;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
h3 {
  color: #fff;
  font-size: 24px;
  word-break: break-all;
  @media (max-width: 768px) {
    font-size: 15px;
  }
}
